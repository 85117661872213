<template>
  <div class="ui-input-password">
    <input
      class="ui-native"
      type="password"
      :value="value"
      @input="onInput($event.target.value)"
    />

    <ul>
      <li
        v-for="(rule, i) in rules"
        :key="i"
        :class="value.match(rule.regex) ? '--valid' : '--invalid'"
      >{{ rule.description }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    rules: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
      this.isValid(value) ? this.$emit('valid') : this.$emit('invalid');
    },

    isValid(value) {
      for (let i = 0; i < this.rules.length; i++) {
        if (!value.match(this.rules[i].regex)) {
          return false;
        }
      }
      return true;
    }
  }
};
</script>

<style lang="scss">
.ui-input-password {
  ul {
    font-size: 0.9em;
    font-weight: bold;

    li {
      &.--valid {
        color: green;
      }

      &.--invalid {
        color: red;
      }
    }
  }
}
</style>